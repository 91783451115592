@import "src/scss/module";

.StatisticsHexagons {
  position: relative;
  :global {
    .vstack {
      @include media-breakpoint-down(sm) {
        & > div {
          width: 100%;
          margin-top: -2rem;

          &:nth-of-type(odd) {
            transform: translate(-12.5%, -12.5%);
          }
          &:nth-of-type(even) {
            transform: translate(12.5%, -12.5%);
          }
        }
      }
      @include media-breakpoint-between(sm, md) {
        & > div {
          margin-top: -2rem;

          &:nth-of-type(1) {
            transform: translate(0%, 12.5%);
          }
          &:nth-of-type(2) {
            transform: translate(0%, 12.5%);
          }

          &:nth-of-type(3) {
            width: 100%;
            transform: translate(0%, 0%);
          }

          &:nth-of-type(4) {
            transform: translate(0%, -12.5%);
          }

          &:nth-of-type(5) {
            transform: translate(0%, -12.5%);
          }
        }
      }
      @include media-breakpoint-between(md, xl) {
        & > div {
          &:nth-of-type(1) {
            width: 33.33%;
            transform: translate(0%, 0%);
          }
          &:nth-of-type(2) {
            width: 33.33%;
            transform: translate(0%, 0%);
          }

          &:nth-of-type(3) {
            width: 33.33%;
            transform: translate(0%, 0%);
          }

          &:nth-of-type(4) {
            width: 33.33%;
            transform: translate(calc(12.5% + 1rem), -25%);
          }

          &:nth-of-type(5) {
            width: 33.33%;
            transform: translate(calc(-12.5% - 1rem), -25%);
          }
        }
      }
    }
  }
}
